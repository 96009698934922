<template>
  <VueJsonPretty 
  v-if="(validatePermission('view', 'admin_area') && adminSettings.showJSON) || forceShow" :deep="deep"
    :data="theData" 
    :editable="editable"
  />

</template>

<script setup>
import VueJsonPretty from 'vue-json-pretty';
const adminSettings = useAdminSettings()
const emit = defineEmits(['change'])

defineProps({
  theData: Object,
  forceShow: {
    type: Boolean,
    default: false
  },
  editable: {
    type: Boolean,
    default: false
  },
  deep: {
    type: Number,
    default: 0
  }
});

</script>